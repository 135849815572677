import { withPreviewResolver } from 'gatsby-source-prismic'
import * as React from 'react'
import linkResolver from '../utils/link-resolver'

const PreviewPage = ({ isPreview }: { isPreview: boolean }) => {
  const previewText = isPreview ? 'Loading' : 'Not a preview!'
  return (
    <div>
      <p>{previewText}</p>
    </div>
  )
}

// @ts-expect-error prismic previews
export default withPreviewResolver(PreviewPage, {
  repositoryName: 'bbv-web',
  linkResolver: () => linkResolver,
})
