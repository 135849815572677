const slugify = require('slugify')

const parameterize = (string) => {
  return slugify(string, {
    replacement: '-',
    lower: true,
    strict: true,
  })
}

const homePath = () => '/'
const eventsPath = () => '/events-community'
const pastEventsPath = () => '/events-community/past'
const eventPath = (uid) => `/events-community/${uid}`
const teamPath = () => '/team'
const investmentsPath = () => '/investments'
const investmentPath = (uid) => `/investments/${uid}`
const portfolioPath = (tag) => `/portfolio${tag ? `?tag=${parameterize(tag)}` : ''}`
const jobsPath = () => '/jobs'
const contactPath = () => '/contact'
const termsPath = () => '/terms-and-conditions'
const privacyPath = () => '/privacy-policy'
const previewPath = () => '/preview'
const customPagePath = (uid) => `/${uid}`

exports.homePath = homePath
exports.investmentsPath = investmentsPath
exports.eventsPath = eventsPath
exports.pastEventsPath = pastEventsPath
exports.eventPath = eventPath
exports.teamPath = teamPath
exports.investmentPath = investmentPath
exports.portfolioPath = portfolioPath
exports.jobsPath = jobsPath
exports.contactPath = contactPath
exports.termsPath = termsPath
exports.privacyPath = privacyPath
exports.previewPath = previewPath
exports.customPagePath = customPagePath
exports.parameterize = parameterize
